<script lang="ts">
  import type { SanityPicture } from "$lib/types/shared";
  import type { PortableTextBlock } from "@portabletext/types";
  import Card from "$lib/Card.svelte";
  import CallMeButton from "$lib/CallMeButton.svelte";
  import { PortableText } from "@portabletext/svelte";
  import { contentBlockComponents } from "$lib/portableText/utils";


  
  interface Props {
    class?: string;
    pictureObject?: SanityPicture | null;
    title?: string;
    content?: PortableTextBlock[];
    showCallMeButton?: boolean;
    callMeButtonText?: string | null;
  }

  let {
    class: classes = "",
    pictureObject = null,
    title = "",
    content = [],
    showCallMeButton = false,
    callMeButtonText = null
  }: Props = $props();
</script>

<div class="relative isolate {classes}">
  <Card
    size="full"
    {title}
    {pictureObject}
    --card-title-size="var(--text-h2-lg)"
    --card-title-leading="1.175"
    --bg-card="transparent"
  >
    <div class=" text-body">
      {#if content?.length}
        <PortableText
          value={content}
          components={contentBlockComponents}
          onMissingComponent={false}
        />
      {/if}

      {#if showCallMeButton}
        <CallMeButton --mx="0 var(--space, 1em)">{callMeButtonText || "Ring meg!"}</CallMeButton>
      {/if}
    </div>
  </Card>
  <div class="max-tablet:triangle-graphic absolute z--1" aria-hidden="true"></div>
</div>

<style lang="postcss">
  .about-us-container {
    overflow-x: clip;
  }

  .triangle-graphic {
    background: var(--triangle) no-repeat center / contain;
    rotate: 260deg;
    top: -35%;
    right: -40%;
    width: 100%;
    height: 120%;
  }
</style>
