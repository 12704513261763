<script lang="ts">
  import type { PortableTextBlock } from "@portabletext/types";
  import type { ArticleCard, ImageWithMeta, Teaser } from "$lib/types/shared";
  import type { Nettbutikk, ProductCategoryPage } from "$lib/types/products";
  import ListArticles from "$lib/ListArticles.svelte";
  import TeaserSection from "./TeaserSection.svelte";
  import Button from "$lib/Button.svelte";
  import ProductsTeaser from "./ProductsTeaser.svelte";
  import FrontPageHero from "./FrontPageHero.svelte";
  import MetaData from "$lib/MetaData.svelte";
  import AboutUs from "./AboutUs.svelte";

  const description =
    "For Ishavskraft er det viktig at du forstår hva du kjøper. Hos oss kan du enkelt sammenligne våre strømavtaler, og velge den avtalen som passer best deg og ditt forbruk.";

  interface Props {
    data: {
      image?: string;
      imageAlt?: string;
      imageWidth?: number;
      imageHeight?: number;
      articles?: Readonly<ArticleCard[]>;
      frontPage?: {
        title: string;
        image: ImageWithMeta;
        interactiveModuleTitle: string;
        interactiveModuleContent: PortableTextBlock[];
        interactiveModuleCallMeButton?: boolean;
        interactiveModuleCallMeButtonText?: string;
        subImage: ImageWithMeta;
      };
      app_teaser?: Teaser;
      category_pages: Readonly<ProductCategoryPage[]>;
      nettbutikk: Readonly<Nettbutikk>;
    };
  }

  let { data }: Props = $props();

  let articles = $derived(Array.isArray(data?.articles) ? data.articles : []);
  let app_teaser = $derived(data?.app_teaser);
  let product_category_pages = $derived(data?.category_pages);
  let nettbutikkGenericContent = $derived(data?.nettbutikk);
</script>

<MetaData
  data={{
    title: `Ishavskraft${data?.frontPage?.title ? ` – ${data.frontPage.title.replace(/^Ishavskraft – /, "")}` : ""}`,
    description,
    image: data?.image,
    imageAlt: data?.imageAlt,
    imageWidth: data?.imageWidth,
    imageHeight: data?.imageHeight,
    type: "website",
  }}
/>

<div class="front-page-content">
  <div class="mb-16 relative z-1">
    <FrontPageHero headingElement={`h1`} pictureObject={data?.frontPage?.image} />
  </div>

  <AboutUs
    pictureObject={data?.frontPage?.subImage}
    title={data?.frontPage?.interactiveModuleTitle}
    content={data?.frontPage?.interactiveModuleContent}
    showCallMeButton={data?.frontPage?.interactiveModuleCallMeButton}
    callMeButtonText={data?.frontPage?.interactiveModuleCallMeButtonText}
    class="my-16 mx-.25edge-x px-.75edge-x laptop:mx-edge-x rounded-2xl bg-$skodde py-8 laptop:px-6"
  />

  {#if product_category_pages}
    <ProductsTeaser {nettbutikkGenericContent} class="relative z-1" />
  {/if}

  <section
    class="my-16 mx-.25edge-x px-.75edge-x laptop:mx-edge-x rounded-2xl bg-$isbre py-8 laptop:px-6"
  >
    <div class="my-16">
      <h2>Test ut vår nye strømkalkulator</h2>
      <p class="text-body">
        Ønsker du å hjelpe oss å teste ut ny og kul funksjonalitet? Trykk på knappen nedenfor og
        prøv vår nye strømkalkulator!
      </p>
      <Button class="mb-4 z-1" href="/privat/hva-koster" arrow>Test vår strømkalkulator</Button>
    </div>
  </section>

  <div class="my-16 pt-16 isolate relative">
    <ListArticles
      listTitle="Artikler"
      listTitleLevel={2}
      listTitleSize="var(--text-h2-lg)"
      {articles}
      displayFields={{ date: false }}
      horizontalScroll={true}
      fixedHeight={true}
      class="relative"
      --bg-card="transparent"
    />

    <p class="mx-edge-x my-0 text-end">
      <Button href="/om/aktuelt" class="button-link min-w-0 px-0" arrow>Alle artikler</Button>
    </p>
    <div class="triangle-graphic absolute z--1"></div>
  </div>

  {#if app_teaser}
    <TeaserSection class="my-16" circleImage={true} teaser={app_teaser} href="/privat/app" />
  {/if}
</div>

<style lang="postcss">
  .front-page-content {
    --text-h2-lg: var(--text-h2);
  }

  @media (--laptop) {
    .front-page-content {
      --w-cover-x: 2.25vw;
    }
  }

  @media (--wide) {
    .front-page-content {
      --w-cover-x: 1.75vw;
    }
  }

  .triangle-graphic {
    background: var(--triangle) no-repeat center / contain;
    rotate: 65deg;
    top: calc(-1 * (12.5% + 5vh));
    left: calc(var(--space-edge-x) - 2em);
    width: min(75vw, 400px);
    height: min(75vw, 400px);
  }
</style>
