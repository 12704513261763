<script lang="ts">
  import Button from "$lib/Button.svelte";
  import CallMeButton from "$lib/CallMeButton.svelte";
  import SanityPicture from "$lib/SanityPicture.svelte";
  import {
    type Nettbutikk,
  } from "$lib/types/products";

  

  interface Props {
    nettbutikkGenericContent: Readonly<Nettbutikk>;
    class?: string;
  }

  let { nettbutikkGenericContent, class: classes = "" }: Props = $props();
</script>

<section class="mx-edge-x flex responsive-grid {classes}">
  <div class="tablet:max-w-1/2">
    <h2 class="mt-3 mb-2">
      {nettbutikkGenericContent?.teaser.title ?? "Produkter som reduserer strømforbruket ditt"}
    </h2>

    <p class="mt-2 text-body">
      {nettbutikkGenericContent?.teaser.description ??
        `Besøk nettbutikken og se hvilke produkter som kan hjelpe deg med å redusere strømforbruket ditt. Vi tilbyr produkter fra kjente merkevarer som Panasonic, Mitsubishi, Zaptec og Fujitsu, som du kan bestille med nedbetaling over strømregninga.`}
    </p>

    <ul class="gap-4 p-0 list-none flex items-center">
      <li>
        <Button class="button" arrow href={`/nettbutikk/`}>
          Nettbutikk
        </Button>
      </li>
      <li>
        <CallMeButton>
          Ring meg
        </CallMeButton>
      </li>
    </ul>
  </div>

  <div class="laptop:max-w-1/2">
        {#if nettbutikkGenericContent?.category_pages_image?.src}
          <SanityPicture
            pictureObject={nettbutikkGenericContent.category_pages_image}
            class="object-cover"
            alt={nettbutikkGenericContent.category_pages_image.alt}
            circle  
          />
        {/if}
  </div>
</section>

<style>
  h2 {
    font-size: var(--text-h2-lg);
  }

  .responsive-grid {
    display: flex;
    flex-direction: column-reverse;
    gap: calc(var(--space-inline, 1em) / 2);
    align-items: center;
  }

  @media (--tablet) {
    .responsive-grid {
      flex-direction: row;
      align-items: center;
      gap: var(--s-10);
      justify-content: space-between;
    }
  }

  .imgHref {
    display: block;
    cursor: pointer;
    border-radius: 50%;
  }
</style>
